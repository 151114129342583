import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

const isProd = process.env.NODE_ENV !== 'development'

Sentry.init({
  dsn: 'https://e86ee4dcd8fff8eb886d7c36329a65c5@o4506892661751808.ingest.us.sentry.io/4507579357528064',
  tracesSampleRate: 1.0,
  enabled: isProd,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
